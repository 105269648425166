import type { Merge } from "type-fest";
import { Missing } from "../AdminTable.tsx";

/**
 * Higher-order component that will only render non-null `value` properties.
 *
 * @example const NonNullCurrency = whenNotNull(CurrencyBalance);
 *          <NonNullCurrency value={item?.balance} />
 */

export function whenNotNull<TProps extends { value: TValue }, TValue = TProps["value"]>(
    Component: React.FC<TProps>,
): React.FC<Merge<TProps, { value?: TValue | null }>> {
    return function WhenNotNull(props) {
        return props.value == null ? (
            <Missing />
        ) : (
            <Component
                // Cast to make TypeScript happy
                {...(props as TProps)}
            />
        );
    };
}
