import C from "./AdminTable.module.css";

import { getOwn, hasOwn } from "@warrenio/utils/collections/getOwnProperty";
import { notNull } from "@warrenio/utils/notNull";
import { useAtom, useAtomValue, useSetAtom } from "jotai/react";
import { Fragment, type ReactNode, type Ref, useEffect, useRef } from "react";
import { flushSync } from "react-dom";
import { entries, isDeepEqual, isEmpty, isNonNull, values } from "remeda";
import type { DraftFunction } from "use-immer";
import { ActionMenu, type ActionMenuItemProps } from "../components/ActionMenu.tsx";
import { WButton } from "../components/button/WButton.tsx";
import { WSearchField } from "../components/forms/WSearchField.tsx";
import { MaskIcon } from "../components/icon/MaskIcon.tsx";
import { ContentLoadingSuspense } from "../components/loading/Loading.tsx";
import { showError } from "../dev/errorStream.ts";
import { locationSlugsAtom } from "../modules/api/locations.store.ts";
import { ErrorMessage } from "../modules/main/ErrorFallback.tsx";
import { Resizable, type ResizerSettings } from "../modules/main/sidebar/Sidebar.tsx";
import { cn } from "../utils/classNames.ts";
import { nothing, produce } from "../utils/immer.ts";
import { atomWithSessionStorage } from "../utils/jotai/atomStorage.ts";
import { sideNavCompactAtom } from "./AdminLayout.store.ts";
import { AdminScrollPane } from "./AdminLayout.tsx";
import { AdminPowerTable, type AdminPowerTableProps } from "./AdminTable.tsx";
import { AdminTitle } from "./AdminTitle.tsx";
import type { GqFilterParams, GqlFieldsOf } from "./FieldConfig.tsx";
import { FilterContext } from "./filters.tsx";
import { OrderDirection } from "./graphql.gen/graphql.ts";
import type { GqData } from "./graphql/extractData.tsx";
import { Pager, type PageVariables, type PagingResult, usePagePager, type UsePager } from "./Pager.tsx";
import { XSticky } from "./StickyArea.tsx";

function toggleOrder(orderDir: OrderDirection): OrderDirection {
    return orderDir === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc;
}

/** Map from field ID to whether it is visible */
type VisibleOverrideState = Record<string, boolean>;

function ColumnVisibilityMenu({
    visibleFieldIds,
    visibleOverride,
    modifyVisibleOverride,
    fields,
}: {
    visibleFieldIds: string[];
    visibleOverride: VisibleOverrideState;
    modifyVisibleOverride: (apply: DraftFunction<VisibleOverrideState>) => void;
    fields: { id: string; title: ReactNode; hidden?: boolean }[];
}) {
    const hasOverrides = !isEmpty(visibleOverride);
    return (
        <ActionMenu
            header={
                <>
                    <div>Columns</div>
                    <div className="ml-auto">
                        <WButton
                            isDisabled={!hasOverrides}
                            color="muted"
                            variant="ghost"
                            size="xs"
                            icon="i-lucide:list-restart"
                            ariaLabel="Reset to default"
                            action={() => modifyVisibleOverride(() => {})}
                        />
                    </div>
                </>
            }
            items={fields.map(
                (f): ActionMenuItemProps => ({
                    id: f.id,
                    title: f.title,
                    action: () =>
                        modifyVisibleOverride((visibleOverride) => {
                            const base = !f.hidden;
                            const current = getOwn(visibleOverride, f.id) ?? base;
                            const target = !current;
                            // Only write an override if the desired value is different from the default
                            if (base !== target) {
                                visibleOverride[f.id] = target;
                            } else {
                                delete visibleOverride[f.id];
                            }
                        }),
                }),
            )}
            selectedKeys={visibleFieldIds}
            selectionMode="multiple"
        >
            <WButton
                color={hasOverrides ? "primary" : "muted"}
                variant="ghost"
                icon="jp-icon-toggles"
                ariaLabel="Filter"
                action={undefined}
            />
        </ActionMenu>
    );
}

const FlexBorder = () => <div className={C.FlexBorder} />;

/**
 * - Key: Arbitrary ID, currently field ID
 * - Value: `null` when filter has not been initialized yet
 */
type QuickFilterState<TOrderFields extends string> = Record<string, GqFilterParams<TOrderFields> | null>;

function QuickFilterEditor<TOrderFields extends string>({
    filters,
    topRef,
    fields,
    modifyFilters,
}: {
    fields: GqlFieldsOf<any, TOrderFields>;
    filters: QuickFilterState<TOrderFields>;
    modifyFilters: (apply: (draft: QuickFilterState<TOrderFields>) => void) => void;
    topRef: Ref<HTMLDivElement>;
}) {
    return (
        <div className={cn(isEmpty(filters) && "hidden", C.FilterBorder, "HStack")} ref={topRef}>
            <div className="p-2 text-muted">Filters:</div>
            <FlexBorder />
            {entries(filters).map(([id, filterParams]) => {
                const field = fields.find((f) => f.id === id)!;
                const Render = notNull(field.filter);
                const order = notNull(field.order);

                return (
                    <Fragment key={id}>
                        <div className="flex flex-row items-center gap-2 py-2 pl-1">
                            {field.title}

                            <FilterContext.Provider
                                value={{
                                    value: filterParams,
                                    setValue: (value) =>
                                        modifyFilters((s) => {
                                            s[id] = value && { field: order, ...value };
                                        }),
                                    field,
                                }}
                            >
                                <Render />
                            </FilterContext.Provider>
                            <WButton
                                key={id}
                                variant="ghost"
                                size="xs"
                                icon="jp-icon-close"
                                action={() =>
                                    modifyFilters((s) => {
                                        delete s[id];
                                    })
                                }
                            />
                        </div>
                        <FlexBorder />
                    </Fragment>
                );
            })}
        </div>
    );
}

interface StandardVariables<TOrderFields extends string> extends PageVariables {
    orderField?: TOrderFields;
    orderDir?: OrderDirection;
    search?: string;
    filters?: GqFilterParams<TOrderFields>[];
    /** NB: This field is not always used. Apollo just ignores this if it is not specified in the query params. */
    locations: string[];
}

// XXX: Use session storage so the state is not synced between different tabs
const stateAtom = atomWithSessionStorage<Record<string, TableViewState<string>>>("adminTableState", {});

interface TableViewState<TOrderFields extends string> {
    orderDir: OrderDirection;
    orderField: TOrderFields | undefined;
    search: string;
    quickFilters: QuickFilterState<TOrderFields>;
    visibleOverride: VisibleOverrideState;
    detailId?: string;
}

export function GraphqlTable<TItem, TOrderFields extends string>({
    title,
    fields,
    useQuery,
    defaults,
    getId,
    renderToolbar,
    renderDetail,
    usePager = usePagePager,
    ...props
}: {
    title: string;
    fields: GqlFieldsOf<TItem, TOrderFields>;
    useQuery: (vars: StandardVariables<TOrderFields>) => GqData<{ items?: TItem[] | null; paging?: PagingResult }>;
    defaults?: Partial<TableViewState<TOrderFields>>;
    renderToolbar?: (item: TItem) => ReactNode;
    renderDetail?: (item: TItem) => ReactNode;
    usePager?: () => UsePager;
} & Omit<AdminPowerTableProps<TItem>, "renderTitle" | "items" | "fields" | "topRef" | "sticky">) {
    //#region Hooks

    const pager = usePager();

    const topRef = useRef<HTMLTableSectionElement>(null);
    const filtersTopRef = useRef<HTMLDivElement>(null);

    //#region View state

    // NB: Use `string` as the state's `TOrderFields` parameter since otherwise Immer has problems with type errors
    // (drafts are invariant so it's impossible to use `TOrderFields` directly since it occurs in both contravariant and covariant positions below)

    const [stateStore, setStateStore] = useAtom(stateAtom);
    useEffect(() => {
        console.debug("StateStore:", stateStore);
    }, [stateStore]);

    const defaultState = {
        orderDir: OrderDirection.Desc,
        orderField: undefined,
        search: "",
        quickFilters: {},
        visibleOverride: {},
        ...defaults,
    };

    const s: TableViewState<string> = getOwn(stateStore, title) ?? defaultState;
    const setState = (modify: DraftFunction<TableViewState<string>>) =>
        setStateStore((prev) =>
            produce(prev, (draft) => {
                const v = produce(draft[title] ?? s, modify);
                if (v != null) {
                    draft[title] = v;
                } else {
                    delete draft[title];
                }
            }),
        );

    const isStateEdited = !isDeepEqual(s, defaultState);

    const { detailId } = s;

    //#endregion

    const setQueryString = (search: string) => {
        // Go to first page when search changes
        pager.goFirst();
        setState((draft) => {
            draft.search = search;
        });
    };

    const order = s.orderField ? { orderField: s.orderField, orderDir: s.orderDir } : undefined;

    const locations = useAtomValue(locationSlugsAtom);
    const query = useQuery({
        ...pager.variables,
        ...order,
        locations,
        search: s.search || undefined,
        filters: values(s.quickFilters).filter(isNonNull),
        // Need a cast here due to the `TOrderFields` issue above
    } as StandardVariables<TOrderFields>);
    const { data, error, loading } = query;

    useEffect(() => {
        if (error) {
            showError("Error in GraphqlTable:", error);
        }
    }, [error]);

    // XXX: Development logging
    useEffect(() => {
        console.debug("QF:", ...values(s.quickFilters));
    }, [s.quickFilters]);

    //#region Detail item

    const hasClosedMenu = useRef(false);
    const setMenuCompact = useSetAtom(sideNavCompactAtom);

    const detailItemRef = useRef<TItem | undefined>(undefined);

    const foundDetailItem = data?.items?.find((item) => getId(item) === detailId);
    // If we can not find the detail item right now, keep the previous one selected
    if (foundDetailItem) {
        detailItemRef.current = foundDetailItem;
    }

    const detailItem = detailId != null ? detailItemRef.current : undefined;

    const openDetail = (item: TItem): void => {
        setState((s) => {
            s.detailId = getId(item);
        });

        // Automatically close the side menu when opening the detail view
        if (!hasClosedMenu.current) {
            setMenuCompact(true);
            hasClosedMenu.current = true;
        }
    };

    const closeDetail = () => {
        setState((s) => {
            delete s.detailId;
        });
        detailItemRef.current = undefined;
    };

    //#endregion Detail item

    //#endregion Hooks

    const visibleFields = fields.filter((f) => getOwn(s.visibleOverride, f.id) ?? !f.hidden);

    const menu = (
        <XSticky>
            <AdminTitle title={title}>
                <WSearchField width="flex-grow ml-auto max-w-20em" value={s.search} onChange={setQueryString} />
                <WButton
                    ariaLabel="Reset"
                    variant="ghost"
                    isDisabled={!isStateEdited}
                    icon="i-lucide:circle-slash-2"
                    action={() => setState(() => nothing)}
                />
                <ColumnVisibilityMenu
                    fields={fields}
                    visibleFieldIds={visibleFields.map((f) => f.id)}
                    visibleOverride={s.visibleOverride}
                    modifyVisibleOverride={(fn) => setState((s) => fn(s.visibleOverride))}
                />
            </AdminTitle>

            <QuickFilterEditor
                topRef={filtersTopRef}
                fields={fields}
                filters={s.quickFilters}
                modifyFilters={(fn) => setState((draft) => fn(draft.quickFilters))}
            />
        </XSticky>
    );

    const table = (
        <AdminPowerTable
            sticky
            topRef={topRef}
            getId={getId}
            fields={visibleFields}
            items={loading ? undefined : error ? [] : data?.items}
            renderTitle={({ id, order, title, filter }) => {
                if (!order) {
                    return title;
                }

                const isOrdering = s.orderField === order;
                const isFiltered = hasOwn(s.quickFilters, id);

                const clickQuickFilter = filter
                    ? () => {
                          // NB: We must fully render the filters element before we scroll to it, otherwise the final scroll position will be wrong (since the height of the page changes during scroll)
                          // https://julesblom.com/writing/flushsync
                          flushSync(() =>
                              setState((s) => {
                                  if (hasOwn(s.quickFilters, id)) {
                                      delete s.quickFilters[id];
                                  } else {
                                      s.quickFilters[id] = null;
                                  }
                              }),
                          );
                          filtersTopRef.current?.scrollIntoView({ block: "nearest", behavior: "smooth" });
                      }
                    : undefined;

                const clickOrder = () =>
                    setState((s) => {
                        s.orderField = order;
                        if (s.orderField === order) {
                            s.orderDir = toggleOrder(s.orderDir);
                        }
                    });

                return (
                    <div className="cursor-pointer HStack gap-1" onClick={clickOrder}>
                        {isOrdering && (
                            <MaskIcon
                                className={`color-muted ${s.orderDir === OrderDirection.Asc ? "jp-icon-caretup" : "jp-icon-caretdown"} size-0.75rem`}
                            />
                        )}
                        {title}
                        {clickQuickFilter && (
                            <WButton
                                variant="ghost"
                                size="xs"
                                color={isFiltered ? "primary" : "muted"}
                                icon="jp-icon-filter-list"
                                action={clickQuickFilter}
                            />
                        )}
                    </div>
                );
            }}
            onClickRow={renderDetail ? openDetail : undefined}
            {...props}
        />
    );

    const footer = (
        <XSticky>
            {error && (
                <div className="bg-error-light p-2 b b-b-solid b-gray-2">
                    <div>Error:</div>
                    <ErrorMessage error={error} />
                </div>
            )}
            <Pager className="p-2" pager={pager} topRef={topRef} result={data?.paging} />
        </XSticky>
    );

    return (
        <div className="flex flex-row size-full">
            <AdminScrollPane>
                {menu}
                {table}
                {footer}
            </AdminScrollPane>
            {detailItem != null && renderDetail && (
                <Resizable settings={resizerSettings}>
                    <div className="h-full overflow-x-auto overflow-y-scroll contain-strict">
                        <div className={cn(C.Toolbar, "HStack")}>
                            {renderToolbar?.(detailItem)}

                            <div className="p-2 ml-auto">
                                <WButton
                                    size="bar"
                                    variant="ghost"
                                    icon="jp-icon-close"
                                    ariaLabel="Close"
                                    action={closeDetail}
                                />
                            </div>
                        </div>

                        <ContentLoadingSuspense>{renderDetail(detailItem)}</ContentLoadingSuspense>
                    </div>
                </Resizable>
            )}
        </div>
    );
}

const resizerSettings: ResizerSettings = {
    minWidth: 300,
    maxWidth: Infinity,
    defaultWidth: 460,
    maxScreenRatio: 0.75,
    storageKey: "adminTableDetailsWidth",
    handleSide: "left",
};
