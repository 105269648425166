import type { ReactNode } from "react";
import { type BaseProps, mcn } from "../utils/baseProps.ts";

/** Children of {@link StickyArea} that should not scroll horizontally (eg. non-table elements) */
export function XSticky({ children, ...props }: { children: ReactNode } & BaseProps) {
    return <div {...mcn("sticky left-0 right-0", props)}>{children}</div>;
}

/** Full-height scrollable area (for containing sticky elements) */
export function StickyArea({ children, ...props }: { children: ReactNode } & BaseProps) {
    return <div {...mcn("relative max-h-full overflow-auto", props)}>{children}</div>;
}
