import { default as T } from "../components/forms/TextField.module.css";
import DP from "./WDatePicker.module.css";

import {
    type CalendarDate,
    type DateValue,
    fromAbsolute,
    getLocalTimeZone,
    toCalendarDate,
} from "@internationalized/date";
import { type ReactNode, useState } from "react";
import type { CalendarProps } from "react-aria";
import {
    Calendar,
    CalendarCell,
    CalendarGrid,
    Dialog,
    DialogTrigger,
    Heading,
    type InputProps,
    Popover,
    TextField,
    type TextFieldProps,
} from "react-aria-components";
import { useDebounceCallback } from "usehooks-ts";
import { WButton } from "../components/button/WButton.tsx";
import { FieldButtonGroup } from "../components/forms/FieldButtonGroup.tsx";
import { type BaseProps, mcn } from "../utils/baseProps.ts";
import { INPUT_DEBOUNCE_MS } from "./debounce.ts";
import { AInput } from "./form/Fields.tsx";

// TODO: Should we use local timezone here?
/** All dates will be considered to be in this timezone */
export const defaultTz = getLocalTimeZone();

/**
 * Convert a text input value to a {@link CalendarDate}
 *
 * @returns `null` if the value is empty, `undefined` if the value is not a valid date
 */
function textToDate(value: string): CalendarDate | null | undefined {
    if (value.trim() === "") {
        return null;
    }

    const ts = Date.parse(value);
    if (Number.isNaN(ts)) {
        return undefined;
    }

    return toCalendarDate(fromAbsolute(ts, defaultTz));
}

function dateToText(date: CalendarDate | null) {
    return date?.toString() ?? "";
}

function WCalendar<T extends DateValue>(props: Omit<CalendarProps<T>, "className"> & BaseProps) {
    return (
        <Calendar {...mcn(DP.Calendar, props)}>
            <div className={DP.header}>
                <WButton variant="ghost" icon="jp-icon-caretleft" slot="previous" action={undefined} />
                <Heading className={DP.Heading} />
                <WButton variant="ghost" icon="jp-icon-caretright" slot="next" action={undefined} />
            </div>
            <CalendarGrid>{(date) => <CalendarCell className={DP.CalendarCell} date={date} />}</CalendarGrid>
        </Calendar>
    );
}

export function WDatePicker({
    defaultValue,
    onChange,
    children,
    placeholder,
    ...props
}: {
    defaultValue: CalendarDate | null;
    onChange: (date: CalendarDate | null) => void;
    children?: ReactNode;
} & BaseProps &
    Pick<TextFieldProps, "autoFocus"> &
    Pick<InputProps, "placeholder">) {
    const [textValue, setTextValue] = useState(dateToText(defaultValue));

    const dateValue = textToDate(textValue);

    const onChangeDebounce = useDebounceCallback(onChange, INPUT_DEBOUNCE_MS);

    return (
        <div>
            <TextField
                className={T.TextField}
                aria-label="Date"
                value={textValue}
                onChange={(value) => {
                    setTextValue(value);
                    const date = textToDate(value);
                    if (date !== undefined) {
                        onChangeDebounce(date);
                    }
                }}
                onBlur={() => {
                    // Normalize the date format on blur
                    const date = textToDate(textValue);
                    if (date !== undefined) {
                        setTextValue(dateToText(date));
                    }
                }}
                isInvalid={dateValue === undefined}
                {...props}
            >
                <FieldButtonGroup>
                    {children}
                    <AInput placeholder={placeholder} />
                    <DialogTrigger>
                        <WButton variant="ghost" icon="jp-calendar-icon" size="xs" action={undefined} />
                        <Popover className="bg-white">
                            <Dialog>
                                <WCalendar
                                    value={dateValue ?? null}
                                    onChange={(date) => {
                                        setTextValue(dateToText(date));
                                        onChange(date);
                                    }}
                                />
                            </Dialog>
                        </Popover>
                    </DialogTrigger>
                </FieldButtonGroup>
            </TextField>
        </div>
    );
}
