import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import type { GqlFieldConfig } from "../FieldConfig.tsx";
import { ExactNumberFilter } from "../filters.tsx";
import { LocationItem } from "./LocationItem.tsx";
import { whenNotNull } from "./whenNotNull.tsx";

export const currencyField = {
    render: whenNotNull(CurrencyBalance),
    align: "text-right",
} satisfies Partial<GqlFieldConfig<any, any, any>>;

export const numberField = {
    align: "text-right",
} satisfies Partial<GqlFieldConfig<any, any, any>>;

export const idField = {
    align: "text-right",
    copyable: true,
} satisfies Partial<GqlFieldConfig<any, any, any>>;

export const locationField = {
    id: "_location",
    title: "DC Location",
    render: whenNotNull(LocationItem),
} satisfies Partial<GqlFieldConfig<any, any, string | null>>;

export const baIdField = {
    ...idField,
    id: "ba_id",
    title: "BA ID",
    filter: ExactNumberFilter,
} satisfies Partial<GqlFieldConfig<any, any, number | null>>;

export const userIdField = {
    ...idField,
    id: "user_id",
    title: "User ID",
    filter: ExactNumberFilter,
} satisfies Partial<GqlFieldConfig<any, any, number | null>>;
